var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-my-lg shadow-2 q-mx-auto bg-white q-pa-lg rounded-section max-width",attrs:{"id":"image"}},[_c('h4',{staticClass:"q-my-none"},[_vm._v(_vm._s(_vm.$t('events.create.image_title')))]),_c('p',{staticClass:"subheading q-mt-none q-mb-lg"},[_vm._v("\n    "+_vm._s(_vm.$t('events.create.image_subtitle'))+"\n  ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-8 col-md-8"},[_c('croppa',{ref:"croppa",attrs:{"width":450,"height":239,"prevent-white-space":false,"initial-size":"contain","accept":'image/*',"placeholder":_vm.$t('events.create.image_add')},on:{"new-image-drawn":function () {
            _vm.disableBlurredBg();
            _vm.enableBlurredBg();
            _vm.getMeta();
          },"image-remove":function () {
            _vm.disableBlurredBg();
            _vm.removedImage();
          },"move":_vm.emitValue,"zoom":_vm.emitValue},model:{value:(_vm.croppedImage),callback:function ($$v) {_vm.croppedImage=$$v},expression:"croppedImage"}},[_c('img',{attrs:{"slot":"initial","crossOrigin":"anonymous","src":_vm.initialImage},slot:"initial"}),_c('canvas',{ref:"canvas",staticStyle:{"z-index":"0","pointer-events":"none","background-color":"transparent","position":"absolute","left":"0","top":"0","visibility":"hidden"},attrs:{"id":"overlay","width":450,"height":239}})])],1),_c('div',{staticClass:"col-xs-12 col-sm-4 col-md-4 q-pa-xs q-pl-md self-end text-center"},[_c('q-btn',{staticClass:"q-mb-lg",attrs:{"size":"sm","tiny":"","rounded":"","flat":"","color":"primary","label":_vm.$t('events.create.image_more_info')},on:{"click":function($event){_vm.imageInfoModal = true}}}),_c('q-dialog',{model:{value:(_vm.imageInfoModal),callback:function ($$v) {_vm.imageInfoModal=$$v},expression:"imageInfoModal"}},[_c('q-card',[_c('q-card-section',{staticClass:"q-pa-lg text-body2",domProps:{"innerHTML":_vm._s(_vm.imageInfoHTML)}})],1)],1),(!_vm.event.image)?_c('div',{staticStyle:{"position":"relative"}},[_c('q-btn',{attrs:{"rounded":"","color":"red","text-color":"white","icon":"add_photo_alternate","label":_vm.$t('events.create.image_add')},on:{"click":function($event){return _vm.$refs.croppa.chooseFile()}}})],1):_vm._e(),(_vm.event.image)?_c('div',[_c('q-btn',{attrs:{"rounded":"","color":"red","text-color":"white","icon":"delete","label":_vm.$t('events.create.image_remove')},on:{"click":function($event){return _vm.removeImage()}}})],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'context-filter-polyfill';
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';

export default {
  name: 'CropImage',
  props: ['event'],
  components: {
    croppa: Croppa.component,
  },
  data() {
    return {
      croppedImage: null,
      imageInfoModal: false,
      initialImage: null,
    };
  },
  methods: {
    removedImage() {
      this.event.existingImage = false;
      this.event.image = null;
      this.$refs.croppa._paintBackground();
      this.$refs.croppa._setPlaceholders();
    },

    removeImage() {
      this.$refs.croppa.remove();
      this.removedImage();
    },

    getMeta() {
      // https://github.com/zhanziyang/vue-croppa/issues/15
      let meta = this.$refs.croppa.getMetadata(); 
      meta.orientation = 1;
      this.$refs.croppa.applyMetadata(meta);
    },

    enableBlurredBg() {
      const cnv = this.$refs.canvas;
      if (!cnv) return; // no canvas
      const ctx = cnv.getContext('2d');
      ctx.fillStyle = 'transparent'; 
      ctx.filter = 'blur(40px)';
      ctx.drawImage(
        this.$refs.croppa.img,
        -10,
        -10,
        cnv.width + 20,
        cnv.height + 20
      );

      this.$refs.croppa._paintBackground = function () { // patch internal fn
        this.ctx.fillStyle = 'white';
        this.ctx.clearRect(0, 0, this.outputWidth, this.outputHeight);
        this.ctx.fillRect(0, 0, this.outputWidth, this.outputHeight);
        this.ctx.drawImage(cnv, 0, 0, this.outputWidth, this.outputHeight);
      };
      this.$refs.croppa._draw();

      setTimeout(() => {
        this.emitValue();
      }, 100);
    },
    disableBlurredBg() {
      const cnv = this.$refs.canvas;
      if (!cnv) return;

      const ctx = cnv.getContext('2d');
      ctx.filter = 'blur(0px)';
      ctx.clearRect(0, 0, cnv.width, cnv.height);

      this.$refs.croppa._paintBackground = function () {
        this.ctx.fillStyle = 'white';
        this.ctx.clearRect(0, 0, this.outputWidth, this.outputHeight);
        this.ctx.fillRect(0, 0, this.outputWidth, this.outputHeight);
      };
      this.$refs.croppa._draw();
    },
    emitValue() {
      if (!this.$refs.croppa) return;

      this.$refs.croppa.generateBlob(
        (blob) => {
          this.event.image = blob;
          this.event.existingImage = true;
          if (this.$refs.croppa)
            this.$emit('onImageChanged', this.$refs.croppa.generateDataUrl());
        },
        'image/jpeg',
        1
      ); //  compressed jpeg file
    },

    getInitialImage() {
      if (
        this.event &&
        this.event.image &&
        (typeof this.event.image).toLowerCase() == 'object'
      ) {
        let reader = new FileReader();
        reader.readAsDataURL(this.event.image);
        reader.onloadend = () => {
          this.initialImage = reader.result;
        };
      } else {
        this.initialImage = this.event.image;
        if (!this.initialImage) {
          this.$refs.croppa._setTextPlaceholder()
        }
      }
    },
  },
  computed: {
    imageInfoHTML() {
      let translation = this.$i18n.t('events.create.image_more_info_text');
      if (translation.match(/\n/)) {
        let nt = translation
          .split('\n')
          .map((element) => {
            return '<li class="q-mb-md">' + element + '</li>';
          })
          .join('');
        return '<ul>' + nt + '</ul>';
      } else return translation;
    },
  },
  watch: {},
  mounted() {
    this.getInitialImage();
  },
  created() {},
};
